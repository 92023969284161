import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, LoadingController, NavController } from '@ionic/angular';
import * as moment from 'moment';
import { ConsumosService } from '../../services/consumos.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-metodos',
  templateUrl: './metodos.page.html',
  styleUrls: ['./metodos.page.scss'],
})
export class MetodosPage implements OnInit {


  constructor(private _route: ActivatedRoute,
    private consumoService: ConsumosService,
              public alertController: AlertController,
              public loadingController: LoadingController,
              public navCtrl: NavController,
              public domsanitizer:DomSanitizer
              ) { }
              expMask = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];
              tarjetaMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

              recibo: any = "";
              usuario: any = "";
              host:any="";
              correo: any = "";
              referencia: any = "";
              total: any = "";
              tarjeta: any = "";
              exp: any = "";
              tipotarjeta: any = "";
              tipo: any = "";

              url: any;
              info: any;
              depto: string = "NA";

              conc: string ="NA";
  ngOnInit() {
   // this.host=this.consumoService.gethost()
    /*if(!localStorage.getItem("LUS_CLAVE")){
      if(localStorage.getItem("LUS_CLAVE") < "1") {
        this.navCtrl.navigateBack("/inicio");
      }
    }*/





    this.recibo = this._route.snapshot.paramMap.get('recibo');

    this.referencia = this.recibo + moment().format("HHmmSS");
   this.total = this._route.snapshot.paramMap.get('total');

    this.usuario = localStorage.getItem("LUS_CLAVE");
    this.correo = localStorage.getItem("LUS_CORREO");

    var URL = 'https://api-sapal-pagos.herokuapp.com/form.php?TOTAL=' + String(this.total).replace('$', '') + '&DEPTO=' + this.depto + '&CONC=' + this.conc + '&FOLIO=' + this.recibo + '&REFERENCE=' + this.referencia + '&USUARIOAPD=' + localStorage.getItem('LUS_CLAVE') + '&CORREOAPD=' + localStorage.getItem('LUS_CORREO');


    this.url_array.push(URL); //[0,1,2,3,4,5]

    //console.log('Valor del array: ',this.url_array[this.url_array.length - 1]);

   console.log('VALOR DE LA POCISION 0 DEL ARRAY: ',  this.url_array[0]);


   // SIEMPRE MANDAMOS LA POCISION CERO DEL ARRAY:
    this.url = this.domsanitizer.bypassSecurityTrustResourceUrl(this.url_array[0]);



    // this.url = this.domsanitizer.bypassSecurityTrustResourceUrl(this.url_array[this.url_array.length - 1]);


  }

  url_array = [];
  validaTarjeta() {
    if(this.tarjeta.charAt(0) == "4") {
      this.tipotarjeta = "VISA";
    } else if(this.tarjeta.charAt(0) == "5") {
      this.tipotarjeta = "MC";
    }
  }
  volver(){
    this.navCtrl.navigateBack("/pagos");

  }
  async go() {
    const loading = await this.loadingController.create({
      cssClass: 'my-loading-class',
      message: '<b>Cargando...</b>'
    });
    await loading.present()
debugger;
    let validatarjeta = this.tarjeta.indexOf("_");
    if(this.tarjeta == "" || validatarjeta >= 0) {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Advertencia',
        message: 'Tarjeta no capturada o con información errónea, revise la información capturada.',
        buttons: ['OK']
      });
      await alert.present();
      loading.dismiss();
      return;
    }
    let validaexp = this.exp.indexOf("_");
    if(this.exp == "" || validaexp >= 0) {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Advertencia',
        message: 'Expiración no capturada o con información errónea, revise la información capturada.',
        buttons: ['OK']
      });
      await alert.present();
      loading.dismiss();
      return;
    }
    if(this.tarjeta.charAt(0) == "4") {
      this.tipotarjeta = "VISA";
    } else if(this.tarjeta.charAt(0) == "5") {
      this.tipotarjeta = "MC";
    }
    debugger;
    if(this.tipo == undefined || this.tipo == "") {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Advertencia',
        message: 'Debe capturar el tipo de tarjeta, revise la información capturada.',
        buttons: ['OK']
      });
      await alert.present();
      loading.dismiss();
      return;
    }
    loading.dismiss();
    debugger;
    var myForm = <HTMLFormElement>document.getElementById('case_form');
    myForm.submit();
  }

}
